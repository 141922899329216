.quote-sec,
.aboutus-sec{
  /* background: linear-gradient(90deg, rgba(148,219,149,0.65) 0%, rgba(237,255,0,0.65) 58%,rgba(230,133,81,0.65) 100%); */
  box-shadow: 0 5px 15px rgba(192, 189, 10, 0.863);
  /* text-align: start; */
  padding: 20px;
  margin: 20px;
  border-radius: 20px;
}
.slider-sec:first-child{
    filter : opacity(0.75) drop-shadow(2px 4px 6px black);
}
.aboutus-sec p,
.aboutus-sec h1{
    padding: 15px;
}
.card-sec{
    display:flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;
    box-shadow: 0 5px 15px rgba(192, 189, 10, 0.863);
    margin: 60px 20px;
    border-radius: 20px;
}
.card-sec .serviceicons-list{
    flex: 1 0 30%; /* explanation below */
    margin: 5px;
}
.card-sec img{
    width: 100px;
    height: 110px;
}
.tab-flex-items {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(16.66% - 5px);
    margin-bottom: 10px;
    box-shadow: 2px 2px 2px 2px black;
    border-radius: 10px;
    padding: 10px;
    text-align: start;
    box-sizing: border-box;
    font-size: 1rem;
    font-weight: 700;
    /* background: linear-gradient(90deg, rgba(148, 219, 149, 0.65) 0%, rgba(237, 255, 0, 0.65) 58%, rgba(230, 133, 81, 0.65) 100%); */
}
/* .line-css {
    background-color: black;
    width: 60px;
    height: 3px;
} */
.quote-sec{
    /* height: 40vh;
    width: 40vw; */
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 2rem;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    display: flex;
    flex-wrap: wrap;
    /* flex-flow: column; */
    /* align-items: center; */
    /* justify-content: center; */
    /* background: rgb(68, 63, 60) url(../images/logo/footer_bg.jpg) no-repeat; */
    background-size: cover;
    padding: 20px;
}
.tab-main-div{
    /* background-image: url(../images/logo/macho_bg\ \(2\).jpg); */
    border: 2px solid;
}
.MuiTabs-scroller {
    /* background: rgb(68, 63, 60) url(../images/logo/footer_bg.jpg) repeat; */
    color: navy;
    background: linear-gradient(90deg, rgb(255 255 255 / 95%) 0%, rgb(223 223 115 / 65%) 58%, #8ca3c6 100%)
}
.MuiButtonBase-root{
    font-size: calc(1rem + 0.2vw) !important;
    font-weight: 20px !important;
    padding: 45px !important;
}
.css-1ujykiq-MuiButtonBase-root-MuiTab-root {
    color: beige;
}
@keyframes fadeIn {
    0% { transform: scale(1); }
  50% { transform: scale(0.85); }
  100% { transform: scale(1); }
  }
  
  /* Apply the animation to the text */
  .quote-sec h1 {
    animation: fadeIn 3s infinite;
    /* background: -webkit-linear-gradient(90deg, rgba(148,219,149,0.95) 0%, rgba(237,255,0,0.65) 58%,rgba(230,133,81,0.65) 100%); */
    /* -webkit-background-clip: text; */
    /* -webkit-text-fill-color: transparent; */
  }