*{
  margin: 0;
  padding: 0;
  font-family: system-ui, sans-serif;
  box-sizing: border-box;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
}
a {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
}
.App{
  text-align: center;
  /* display: flex;
  flex-direction: row; */
  
}
body {
	/* background: linear-gradient(90deg, rgba(148,219,149,1) 0%, rgba(237,255,0,1) 58%,rgba(230,133,81,1) 100%); */
	background-size: 100% 100%;
	animation: gradient 15s ease infinite;
	height: 100vh;
	/* background-image: url(../images/logo/macho_bg\ \(2\).jpg); */
	background-repeat: repeat;
	background-size: cover;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


