.footer {
    background: rgb(68, 63, 60) url(../images/logo/footer_bg.jpg) no-repeat;
    color: white;
    bottom: 0;
    width: 100%;
    height:50%;
    /* position: fixed; */
    display:grid;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    padding: 20px;
}
.footer div{
    padding: 10px;
}
.footer a{
    color:chartreuse;
}
.footer-text{
    font-size: 15px;
}